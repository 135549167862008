import React, { useState, useEffect, FormEvent } from "react";
import {
  Button,
  Input,
  FormGroup,
  Col,
  Card,
  CardBody,
  Row,
  Label,
} from "reactstrap";

import { ModalHistoricoJustificativa } from "../../components/ModalHistoricoJustificativa";

import api from "../../services/api";
import { useParams } from "react-router-dom";

import Loading from "../../components/Loading";

import { useHistory } from "react-router-dom";

import { toastr } from "react-redux-toastr";
import { AxiosError } from "axios";
import { useModalJustificativa } from "../../context/ModalJustificativaContext";

import "./styles.css";
interface justificativasProps {
  historicoDeJustificativasInseridas_arr: string[];
  id: string;
  idCryptografado: string;
  opcoesDeJustificativas: string[];
  textoDaNotificacacao: string;
  tipoDoPonto: string;
  grafico?: string;
}
interface rashProps {
  rashNotification?: string;
  isRedirect?: boolean;
}

export const FormJustificativa = ({
  rashNotification = "",
  isRedirect,
}: rashProps) => {
  const [outra, setOutra] = useState(false);
  const [userResponse, setUserResponse] = useState("");

  const [loading, setLoading] = useState(false);

  const [justificativas, setJustificativas] = useState<justificativasProps>(
    {} as justificativasProps
  );

  const { rash }: { rash: string } = useParams();
  const history = useHistory();
  const { toggleModalJustificativa } = useModalJustificativa();

  useEffect(() => {
    api.post(`/logs`, {
      url: `/justificativa/${rash ? rash : rashNotification}`,
    });
  }, [rash, rashNotification]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      if (userResponse !== "") {
        api
          .post(
            `/justificativa/justification/${rash ? rash : rashNotification}`,
            {
              justification: userResponse,
            }
          )
          .then(() => {
            toastr.info("Sucesso", "Justificativa inserida com sucesso!");
            if (isRedirect) {
              history.push(
                `/dashboard/${justificativas.tipoDoPonto.toLocaleLowerCase()}/${
                  justificativas.idCryptografado
                }`
              );
            } else {
              toggleModalJustificativa();
            }
          })
          .catch((error: AxiosError) =>
            toastr.error("Error", error.response?.data.message)
          )
          .finally(() => setLoading(true));
      } else {
        toastr.warning("Alerta", "Todos os campos precisam ser preenchidos!");
      }
    } catch (error) {
      toastr.error("Error", "Error ao inserir ");
    }
  }

  function selectOutro() {
    setOutra(true);
  }
  useEffect(() => {
    api
      .post(`/justificativa/${rash ? rash : rashNotification}`)
      .then((response) => {
        setJustificativas(response.data);
        console.log(response);
      })
      .finally(() => setLoading(true));
  }, [rash, rashNotification]);

  return loading ? (
    <>
      <Row sm={12} style={{ justifyContent: "center" }}>
        <Card style={{ width: "50rem" }}>
          <CardBody>
            <form onSubmit={handleSubmit}>
              {justificativas.grafico && justificativas.grafico.length > 0 && (
                <Col sm={12}>
                  <img
                    src={justificativas.grafico}
                    alt="Gráfico"
                    style={{ width: "100%", display: "flex" }}
                  />
                </Col>
              )}
              <Col sm={12}>
                <p
                  style={{
                    marginLeft: -10,
                  }}
                >
                  <b>Alerta:</b>
                </p>
                <p
                  style={{
                    marginLeft: -10,
                    textAlign: "justify",
                  }}
                >
                  {justificativas.textoDaNotificacacao}
                </p>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "right",
                }}
                sm={12}
              >
                <ModalHistoricoJustificativa
                  rashModal={rash ? rash : rashNotification}
                />
              </Col>

              <Col sm={12}>
                <p
                  style={{
                    marginLeft: -10,
                  }}
                >
                  <b>Escolha a opção que descreve o ocorrido:</b>
                </p>
              </Col>

              <FormGroup row className="ml-2">
                <Col sm={12}>
                  <div className="custom-controls-stacked">
                    {justificativas.opcoesDeJustificativas?.map(
                      (opcaoJustificativa, index) => (
                        <FormGroup>
                          <Input
                            type="radio"
                            key={index}
                            onChange={() => setOutra(false)}
                            className="radioButton"
                            id={`justificativa-${index}`}
                            name="justificativa"
                            label={opcaoJustificativa}
                            value={opcaoJustificativa}
                            onClick={() => setUserResponse(opcaoJustificativa)}
                          />
                          <Label>{opcaoJustificativa}</Label>
                        </FormGroup>
                      )
                    )}

                    <FormGroup>
                      <Input
                        type="radio"
                        className="radioButton"
                        id="justificativa-100"
                        name="justificativa"
                        label="Outra"
                        value="Outra"
                        onClick={() => selectOutro()}
                      />
                      <Label>Outra</Label>
                    </FormGroup>
                  </div>
                </Col>
                {outra && (
                  <Col sm={12}>
                    <Input
                      type="textarea"
                      className="outra"
                      id="justificativa"
                      name="justificativa"
                      required
                      onChange={(e) => setUserResponse(e.target.value)}
                      maxLength={140}
                      style={{
                        width: "100%",
                        height: "4rem",
                        fontStyle: "normal",
                        textAlign: "left",
                        margin: 0,
                        padding: 0,
                        paddingLeft: 10,
                        paddingBottom: 40,
                        justifyContent: "start",
                        marginTop: 10,
                        fontSize: "1rem",
                        resize: "none",
                      }}
                      autoFocus
                    />
                  </Col>
                )}

                <Col sm={12}>
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: "#262F3D",
                      marginTop: 10,
                      alignItems: "center",
                      textAlign: "center",
                      justifyItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Salvar
                  </Button>
                </Col>
              </FormGroup>
            </form>
          </CardBody>
        </Card>
      </Row>
    </>
  ) : (
    <Loading width={10} height={10} color="primary" />
  );
};
